.footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 92px;
  align-items: center;
}

.company-title {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.38px;
  color: #8E8E93;
}
