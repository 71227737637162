
.social-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

}

.social-btn-title {
  padding: 0 8px 0 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #6CE997;
  /* identical to box height, or 133% */

  letter-spacing: -0.5px;
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  
  text-decoration: none;
  cursor: pointer;
}
