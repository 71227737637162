$extra-small: 0px;
$small: 667px;
$medium: 900px;
$large: 1200px;

.feature-row-container {

  @media screen and (min-width: $extra-small) {
    padding: 0 0 0 0;
  }

  @media screen and (min-width: $small) {
    padding: 0 0 0 0;
  }

  @media screen and (min-width: $medium) {
    padding: 0 0 0 0;
  }

  @media screen and (min-width: $large) {
    padding: 0 0 0 0;
  }
}

.feature-image {
  object-fit: contain;
  width: 100%;

  @media screen and (min-width: $extra-small) {
    max-width: 220px;
  }

  @media screen and (min-width: $small) {
    //max-width: 280px;
  }

  @media screen and (min-width: $medium) {
    max-width: 100%;
  }

  @media screen and (min-width: $large) {
    max-width: 100%;
    padding: 0 0 0 0;
  }
}

.feature-title {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;

  /* or 116% */
  text-align: left;
  letter-spacing: 0.374px;

  color: #FFFFFF;

  @media screen and (min-width: $extra-small) {
    font-size: 28px;
    line-height: 33px;
    max-width: 260px;
  }

  @media screen and (min-width: $small) {
    font-size: 34px;
    line-height: 41px;
    max-width: 461px;
  }

  @media screen and (min-width: $medium) {
    padding: 100px 0 0 0;
    font-size: 38px;
    line-height: 44px;
    max-width: 100%;
  }

  @media screen and (min-width: $large) {
    padding-top: 100px;
    font-size: 38px;
    line-height: 44px;
    max-width: 100%;
  }
}

.feature-subtitle {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-align: left;
  letter-spacing: 0.38px;


  color: #8E8E93;

  @media screen and (min-width: $extra-small) {
    font-size: 17px;
    line-height: 22px;
    padding-top: 20px;
    padding-bottom: 50px;
    max-width: 260px;
  }

  @media screen and (min-width: $small) {
    font-size: 17px;
    line-height: 22px;
    padding-top: 30px;
    max-width: 461px;
  }

  @media screen and (min-width: $medium) {
    font-size: 20px;
    line-height: 24px;
    padding-top: 30px;
    max-width: 100%;
  }

  @media screen and (min-width: $large) {
    font-size: 20px;
    line-height: 24px;
    padding-top: 30px;
    max-width: 100%;
  }
}