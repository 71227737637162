
$extra-small: 0px;
$small: 667px;
$medium: 900px;
$large: 1200px;



.headline-container {

}

.spacer {
  width: 100%;


  @media screen and (min-width: $extra-small) {
    height: 20px;
  }

  @media screen and (min-width: $small) {
    height: 30px;
  }

  @media screen and (min-width: $medium) {
    height: 80px;
  }

  @media screen and (min-width: $large) {
    height: 100px;
  }
}

.headline-image {
  object-fit: contain;
  max-width: 1080px;
  width: 100%;
}

.headline-title {
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: left;
  color: white;
  letter-spacing: 0.374px;

  @media screen and (min-width: $extra-small) {
    font-size: 30px;
    line-height: 41px;
    width: 100%;
    max-width: 300px;
    padding: 30px 0 0 0;
  }

  @media screen and (min-width: $small) {
    font-size: 38px;
    line-height: 44px;
    width: 100%;
    max-width: 465px;
    padding: 110px 0 0 0;
  }

  @media screen and (min-width: $medium) {
    font-size: 48px;
    line-height: 56px;
    width: 100%;
    max-width: 660px;
    padding: 120px 0 40px 0;
  }

  @media screen and (min-width: $large) {
    font-size: 48px;
    line-height: 56px;
    width: 100%;
    max-width: 879px;
    padding: 120px 0 40px 0;
  }
}


.feature-list-title {
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  /* identical to box height, or 116% */

  letter-spacing: 0.374px;

  color: #FFFFFF;

  @media screen and (min-width: $extra-small) {
    font-size: 28px;
    line-height: 33px;
  }

  @media screen and (min-width: $small) {
    font-size: 28px;
    line-height: 33px;
  }

  @media screen and (min-width: $medium) {
    font-size: 34px;
    line-height: 41px;
  }

  @media screen and (min-width: $large) {
    font-size: 38px;
    line-height: 56px;
    //width: 660px;
  }

}

.available-title {
  font-family: 'SF Pro Text', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  padding-bottom: 50px;
  /* identical to box height, or 116% */

  letter-spacing: 0.374px;

  color: #FFFFFF;

  @media screen and (min-width: $extra-small) {
    font-size: 28px;
    line-height: 33px;
  }

  @media screen and (min-width: $small) {
    font-size: 28px;
    line-height: 33px;
  }

  @media screen and (min-width: $medium) {
    font-size: 34px;
    line-height: 41px;
  }

  @media screen and (min-width: $large) {
    font-size: 38px;
    line-height: 56px;
    width: 660px;
  }

}

.feature-list-item {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 24px;
  font-size: 20px;
  color: #8E8E93;
  text-align: left;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.38px;

  /* SystemGray/Dark */

  color: #8E8E93;

  @media screen and (min-width: $extra-small) {
    font-size: 17px;
    line-height: 22px;
  }

  @media screen and (min-width: $small) {
    font-size: 17px;
    line-height: 22px;
  }

  @media screen and (min-width: $medium) {
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (min-width: $large) {
    font-size: 20px;
    line-height: 24px;
  }

  a {
    color: #6CE997;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}

.email-link {
  font-family: 'SF Pro Display', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 600;
  padding-bottom: 24px;
  font-size: 20px;
  text-align: left;
  line-height: 24px;
  /* identical to box height, or 120% */

  letter-spacing: 0.38px;

  /* SystemGray/Dark */


  @media screen and (min-width: $extra-small) {
    font-size: 17px;
    line-height: 22px;
  }

  @media screen and (min-width: $small) {
    font-size: 17px;
    line-height: 22px;
  }

  @media screen and (min-width: $medium) {
    font-size: 20px;
    line-height: 24px;
  }

  @media screen and (min-width: $large) {
    font-size: 20px;
    line-height: 24px;
  }
}