
$extra-small: 0px;
$small: 667px;
$medium: 900px;
$large: 1200px;


.Header-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.App-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: calc(10px + 2vmin);
    color: white;
    background: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    position: sticky;
    top: 0;

    @media screen and (min-width: $extra-small) {
        height: 92px;
    }

    @media screen and (min-width: $small) {
        height: 120px;
    }

    @media screen and (min-width: $medium) {
        height: 180px;
    }

    @media screen and (min-width: $large) {
        height: 180px;
    }
}

.Social-btn-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;

    @media screen and (min-width: $extra-small) {
        padding-right: 30px;
    }

    @media screen and (min-width: $small) {
        width: 100%;
    }

    @media screen and (min-width: $medium) {
        width: 100%;
    }

    @media screen and (min-width: $large) {
        width: 100%;
    }
}

.Logo-icon {
    @media screen and (min-width: $extra-small) {
        width: 54px;
        height: 44px;
    }

    @media screen and (min-width: $small) {
        width: 74px;
        height: 60px;
    }

    @media screen and (min-width: $medium) {
        width: 99px;
        height: 80px;
    }

    @media screen and (min-width: $large) {
        width: 99px;
        height: 80px;
    }
}

.App-store-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 70px;

    @media screen and (min-width: $extra-small) {
        height: 56px;
        width: 168px;
    }

    @media screen and (min-width: $small) {
        height: 46px;
        width: 142px;
    }

    @media screen and (min-width: $medium) {
        height: 56px;
        width: 168px;
    }

    @media screen and (min-width: $large) {
        height: 56px;
        width: 168px;
    }
}